import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
// import 'node_modules/react-modal-video/scss/modal-video.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class Banner extends Component {

	state = {
		isOpen: false
	};

	openModal = () => {
		this.setState({ isOpen: true })
	};

	render() {
		return (
			<>
				<Carousel
					autoPlay={true}
					infiniteLoop={true}
					interval={5000}
					showStatus={false}
					showThumbs={false}
				>
					<div id="home" className="main-banner">
						<div className="main-banner-content">
								<div className="masthead">
									<div className="masthead-bg">
										<div className="masthead-content">
											<h1 className="masthead-heading">The Ultimate Platform for learning about the African Music Industry.</h1>
											<p className="masthead-text">Enabling African kreeyatives looking to enter the music industry by providing
												premium curated resources, access and tools necessary in the steps needed
												to become a major player in the future of the African music business
											</p>
											<p className="masthead-text">
												Join now and become a Master Of The Industry @MOTI
											</p>

											<div className="btn-box">
												<Link to="/signup" className="btn btn-primary">
													Sign Up
												</Link>

												<div
													className="popup-youtube video-btn"
													onClick={e => { e.preventDefault(); this.openModal() }}
												>
													<i className="fab fa-google-play"></i>
													Play Video
												</div>
											</div>
										</div>
									</div>
								</div>
							<div class="overlay">
							</div>
							<div className='video-container-box'>
								<video autoplay="autoplay" class="background" loop="loop" muted="muted" playsinline="playsinline" source src="https://d1jxpqxy0qzag1.cloudfront.net/cf1c0764-e8d4-429c-867b-c31c65850645/mp4/WELCOME TO MOTI_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4" type="video/mp4">
								</video>
							</div>
						</div>
					</div>
					{/* <div id="home" className="main-banner-slide-2">
						<div className="main-banner-content">
								<div className="masthead">
									<div className="masthead-bg">
										<div className="masthead-content">
											<h1 className="masthead-heading">DRAKE HINTS AT NEW MIXTAPE</h1>
											<p className="masthead-text">Canadian wonder rapper, Drake, hints at the
											possibility of a follow up mixtape to Nothing Was The Same. 
											</p>
											<p className="masthead-text">
											Having seen
											significant success with his previous project, the Canadian born rapper
											now has his eyes set on topping that with a new mixtape that could drop 
											as early has June.
											</p>
											<p className="masthead-text">
												Find out more below, by playing the video. Don't forget to join MOTI!
											</p>

											<div className="btn-box">
												<Link to="/signup" className="btn btn-primary">
													Sign Up
												</Link>

												<div
													className="popup-youtube video-btn"
													onClick={e => { e.preventDefault(); this.openModal() }}
												>
													<i className="fab fa-google-play"></i>
													Play Video
												</div>
											</div>
										</div>
									</div>
								</div>
							<div className='video-container-box'>
								<video autoplay="autoplay" class="background" loop="loop" muted="muted" playsinline="playsinline" source src="https://www.youtube.com/watch?v=dxOIpumyJAU" type="video/mp4">
								</video>
							</div>
						</div>
					</div> */}

					{/* <div id="home" className="main-banner-slide-3">
						<div className="main-banner-content">
								<div className="masthead">
									<div className="masthead-bg">
										<div className="masthead-content">
											<h1 className="masthead-heading">KANYE DROPS NEW SINGLE</h1>
											<p className="masthead-text">Super-producer and chicago native, Kanye
											West has dropped a new single titled "My Life Was Never Eazy" 
											</p>
											<p className="masthead-text">
											Having recently ended his longterm marriage to reality star turned mogul,
											Kim Kardashian, Kanye let's it all loose on this track. Talking about the 
											pain of loss and divorce, this track is bound to move you!
											</p>
											<p className="masthead-text">
												Find out more below, by playing the video. Don't forget to join MOTI!
											</p>

											<div className="btn-box">
												<Link to="/signup" className="btn btn-primary">
													Sign Up
												</Link>

												<div
													className="popup-youtube video-btn"
													onClick={e => { e.preventDefault(); this.openModal() }}
												>
													<i className="fab fa-google-play"></i>
													Play Video
												</div>
											</div>
										</div>
									</div>
								</div>
							<div className='video-container-box'>
								<video autoplay="autoplay" class="background" loop="loop" muted="muted" playsinline="playsinline" source src="https://www.youtube.com/watch?v=dxOIpumyJAU" type="video/mp4">
								</video>
							</div>
						</div>
					</div> */}
          
				</Carousel>
				<ModalVideo
					channel='custom'
					isOpen={this.state.isOpen}
					url='https://d1jxpqxy0qzag1.cloudfront.net/199dbd25-c5a1-421d-8564-b1ec62bf12be/mp4/MASTERS%20OF%20THE%20INDUSTRY%20LAUNCH%20-%20Munya%20Chanetsa_Mp4_Avc_Aac_16x9_1280x720p_24Hz_8.5Mbps_qvbr.mp4'
					onClose={() => this.setState({ isOpen: false })}
				/>
			</>
		);
	}
}

export default Banner;
