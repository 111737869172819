// const env = process.env.REACT_ENV || 'development' // options: 'development', 'production'
const env = 'production'

const domains = {
  development: 'http://localhost:1337',
  production: 'https://moti-africa-node.herokuapp.com',
}

const keys = {
  production: {
    paystackKey: 'pk_live_37cf1fe75d8ac9e93101f318053425cefc42853f',
    payStackSecretKey: 'sk_live_42e8de9063482a127e4f80980b62a65fb88a9e22',
    awsRegion: 'eu-west-1',
    awsAccessKeyId: 'AKIA2LQB654ADSKELSWZ',
    awsSecretAccessKey: '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',
    awsMusicBucketName: 'moti-music-files',
    awsSignaturesBucketName: 'moti-signature-files'
  },
  development: {
    paystackKey: 'pk_test_5037003548f13398d4ac58beb1563ece6072382b',
    payStackSecretKey: 'sk_test_5bc763e89c53a950a64cc06f3013750725b6cce1',
    awsRegion: 'eu-west-1',
    awsAccessKeyId: 'AKIA2LQB654ADSKELSWZ',
    awsSecretAccessKey: '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',
    awsMusicBucketName: 'moti-music-files-test',
    awsSignaturesBucketName: 'moti-signature-files-test'
  }
}

const envKeys = keys[env]

const Cdns = {
  development: {
    songs: 'https://dlsmjl80plx4g.cloudfront.net/',
    signatures: 'https://drlntvhlrdhsg.cloudfront.net/',
  },
  production: {
    songs: 'https://d3nlpnkdvykg21.cloudfront.net/',
    signatures: 'https://d39qibucq8bhhs.cloudfront.net/',
  },
}

const cloudFrontUrls = Cdns[env]

let domain = domains[env]

const endpoints = {
  users: {
    register: `${domain}/api/v1/users/signup`, // POST
    login: `${domain}/api/v1/users/login`,     // PUT
    get: `${domain}/api/v1/users/:userId`,     // GET
    update: `${domain}/api/v1/users/update`,   // PUT
    sendPasswordRecoveryEmail: `${domain}/api/v1/users/send-password-recovery-email`, // POST
    resetPassword: `${domain}/api/v1/users/update-password-and-login`, // POST
    inviteFriend: `${domain}/api/v1/users/invite-friend` // POST
  },
  splits: {
    create: `${domain}/api/v1/splits/create`, // POST
    update: `${domain}/api/v1/splits/update`, // PUT
    accept: `${domain}/api/v1/splits/accept`, // POST
    getAll: `${domain}/api/v1/splits/get-all/:userUuid`, // GET
    generatePdf: `${domain}/api/v1/splits/generate-pdf`, // POST
  },
  videos: {
    getAll: `${domain}/api/v1/videos/get-all/:userId`, // GET
  },
  videoProgress: {
    create: `${domain}/api/v1/video-progress/create`, // POST
    get: `${domain}/api/v1/video-progress/get/:userId`, // GET
    update: `${domain}/api/v1/video-progress/update` // PUT
  },
  songs: {
    upload: `${domain}/api/v1/metadata/upload`, // POST
    update: `${domain}/api/v1/metadata/update`, // POST
    delete: `${domain}/api/v1/metadata/delete`, // POST
    getAll: `${domain}/api/v1/metadata/get-all/:userId`, // GET
  },
  questions: {
    create: `${domain}/api/v1/question/create`, // POST
    getAll: `${domain}/api/v1/question/get-all/:userId` //GET
  },
  billing: {
    createEditLink: 'https://api.paystack.co/subscription/:code/manage/link' // GET
  }
}

const userRoles = [
  'performing-artist',
  'songwriter',
  'producer',
  'sound-engineer',
  'publisher',
  'aggregator',
  'session-musician',
  'cd-manufacturer',
  'booking-agent',
  'road-manager',
  'business-manager',
  'promoter',
  'tour-manager',
  'videographer',
  'photographer',
  'public-relations'
]

const userRolesMap = {
  'performing-artist': 'Performing Artist',
  'songwriter': 'Songwriter',
  'producer': 'Producer',
  'sound-engineer': 'Sound Engineer',
  'publisher': 'Publisher',
  'aggregator': 'Aggregator',
  'session-musician': 'Session Musician',
  'cd-manufacturer': 'CD Manufacturer',
  'booking-agent': 'Booking Agent',
  'road-manager': 'Road Manager',
  'business-manager': 'Business Manager',
  'promoter': 'Promoter',
  'tour-manager': 'Tour Manager',
  'videographer': 'Videographer',
  'photographer': 'Photographer',
  'public-relations': 'Public Relations'
}

export {
  userRoles,
  endpoints,
  cloudFrontUrls,
  userRolesMap,
  envKeys
}